<template>
	<div>
		<section class="header-bg" style="">
		</section>
		<section class="container-1200" style="margin-top: 40px;">

			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Terms of
						Use</strong></p>
			</div>
		</section>
		<section class="container-1200" style="margin-top: 40px;">

			<div style="margin-left: 40px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">SOFTWARE FREE USE AGREEMENT</div>
				<p style="margin-top: 16px;">This Software Free Use Agreement (the "Agreement") is entered into by and
					 between Contrimetric, located at B-7-28 Horizon Suites Blok B, 
					 Kota Warisan 43900 Sepang, Selangor, Malaysia (hereinafter referred to as the "Developer"), 
					 and [User's Name/Company Name], located at [User's Address] (hereinafter referred to as the "User").</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">1. DEFINITIONS</div>
				<p style="margin-top: 16px;">1.1 "Software" refers to the computer program developed by the Developer, 
					known as [Contrimetric·Plugin], which is described as [Contrimetric is shortened from Contribution Metric,
					 which is called Article Citation Contribution Indicator(ACCI), or Contribution Factor(CF). 
					 Each academic paper has two ACCI values: ACCI-I(CF1) and ACCI-II(CF2), which is a new designed indicator for scientific contribution.]</p>
				<p>1.2 "Use" means downloading, installing, and running the Software for the User's own purposes.</p>	 
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">2. LICENSE GRANT</div>
				<p style="margin-top: 16px;">2.1 The Developer hereby grants the User a non-exclusive, non-transferable, royalty-free license to Use the Software.</p>
				<p>2.2 This license permits the User to Use the Software for an unlimited period of time, subject to the terms and conditions of this Agreement.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">3. TERMS</div>
				<p style="margin-top: 16px;">3.1 The User agrees to Use the Software for free and undertakes not to charge any fees or receive any revenue from third parties for the Use of the Software,
					 unless explicitly permitted by the Developer in writing.</p>
				<p>3.2 The User agrees to provide written notice to the Developer before uninstalling the Software and to promptly delete all installed copies of the Software upon uninstallation.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">4. WARRANTIES AND DISCLAIMER</div>
				<p style="margin-top: 16px;">4.1 The Developer warrants that to the best of their knowledge, the Software does not infringe any third-party intellectual property rights.</p>
				<p>4.2 The Developer makes no warranties, express or implied, regarding the suitability, completeness, or accuracy of the Software.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">5. TERMINATION</div>
				<p style="margin-top: 16px;">5.1 This Agreement may be terminated by either party upon written notice. Upon termination, the User shall immediately cease Use of the Software and delete all installed copies of the Software.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">6. APPLICABLE LAW AND DISPUTE RESOLUTION</div>
				<p style="margin-top: 16px;">6.1 This Agreement shall be governed by and construed in accordance with the laws of Malaysia.</p>
				<p>6.2 Any disputes arising out of or in connection with this Agreement shall be first resolved through amicable negotiations between the parties. If the dispute cannot be resolved amicably, it shall be submitted to the competent courts of Malaysia for resolution.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">7. MISCELLANEOUS</div>
				<p style="margin-top: 16px;">7.1 This Agreement constitutes the entire agreement between the parties regarding the Use of the Software and supersedes any prior oral or written agreements between the parties.</p>
				<p>7.2 Any modification to this Agreement shall be effective only if it is made in writing and signed by both parties.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<p>This Agreement is effective as of the date of signing below.</p>
			</div>

			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<div class="titleSecond">Developer:</div>
				<p style="margin-top: 16px;">[Contrimetric]</p>
				<p style="margin-top: 10px;">Signature: _________________</p>
				<p style="margin-top: 10px;">Date: _________________</p>
				<div class="titleSecond" style="margin-top: 30px;">User:</div>
				<p style="margin-top: 16px;">[User's Name/Company Name]</p>
				<p style="margin-top: 10px;">Signature: _________________</p>
				<p style="margin-top: 10px;">Date: _________________</p>
			</div>
			
			<div style="margin:30px 0px 0px 80px;text-align: justify;line-height: 22px;">
				<p>Remember to consult with legal professionals to ensure compliance with local laws and regulations in Malaysia and to protect your interests.</p>
			</div>


		</section>
	</div>

</template>
<script>
	export default {
		name: "policy",
		data() {
			return {};
		},
		watch: {},
		created() {

		},
		mounted() {},
		methods: {

		},
	}
</script>

<style lang="scss" scoped>
	.titleFirst {
		font-size: 22px;
		font-weight: bold;
	}

	.titleSecond {
		font-size: 20px;
		font-weight: bold;
	}
</style>